<template>
  <div class="px-2 mt-1">
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'pending-salary' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        PENDING SALARIES
        <span class="ml-2" v-if="counter > 0">
          <feather-icon
            icon
            :badge="counter <= 99 ? counter : '99+'"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'completed-salary' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        COMPLETED
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.fullPath" />
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  async created() {
    await this.$store.dispatch("SalaryRecordStore/A_GET_COUNTER_SALARY")
  },
  computed: {
    isManagement() {
      return this.$route.matched[0].meta.module === 16
    },
    ...mapGetters({
      counter_management: "SalaryRecordStore/G_COUNT_MANAGEMENT",
      counter_rrhh: "SalaryRecordStore/G_COUNT_RRHH",
    }),
    counter() {
      return this.isManagement ? this.counter_management : this.counter_rrhh
    },
  },
}
</script>
